import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        
      <path length="50" width="50" fill="currentColor" d="M 44.19 48.927 L 56.495 48.927 A 31.718 31.718 0 0 1 54.716 57.015 A 23.352 23.352 0 0 1 48.096 66.676 Q 40.951 72.892 29.205 73.044 A 45.728 45.728 0 0 1 28.614 73.048 A 30.384 30.384 0 0 1 20.159 71.911 A 25.763 25.763 0 0 1 13.648 69.02 A 26.083 26.083 0 0 1 4.348 58.931 A 31.752 31.752 0 0 1 3.663 57.569 Q 0.147 50.148 0.001 40.333 L 0.001 33.692 A 48.182 48.182 0 0 1 0.837 24.51 A 36.796 36.796 0 0 1 3.565 15.968 A 28.333 28.333 0 0 1 8.976 8.017 A 25.763 25.763 0 0 1 13.795 4.151 A 27.509 27.509 0 0 1 25.735 0.172 A 34.356 34.356 0 0 1 29.2 0.001 Q 40.967 0.001 48.145 6.398 Q 55.323 12.794 56.495 24.464 L 44.19 24.464 A 30.131 30.131 0 0 0 43.481 20.49 Q 42.965 18.457 42.181 16.847 A 11.756 11.756 0 0 0 39.722 13.404 Q 36.722 10.568 31.385 10.102 A 25.169 25.169 0 0 0 29.2 10.011 Q 21.143 10.011 16.822 15.895 A 19.611 19.611 0 0 0 14.049 21.455 Q 13.206 24.035 12.794 27.176 A 49.36 49.36 0 0 0 12.403 33.155 L 12.403 39.454 A 51.639 51.639 0 0 0 12.786 45.969 Q 13.196 49.181 14.044 51.813 A 20.228 20.228 0 0 0 16.529 57.032 A 13.261 13.261 0 0 0 26.192 62.935 A 18.746 18.746 0 0 0 28.614 63.087 A 25.921 25.921 0 0 0 32.615 62.799 Q 34.728 62.468 36.421 61.758 A 11.09 11.09 0 0 0 39.551 59.815 A 11.425 11.425 0 0 0 42.084 56.44 Q 42.895 54.851 43.438 52.83 A 29.483 29.483 0 0 0 44.19 48.927 Z"/>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
